<template>
  <div>
    <MainStepper e1="5" />
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="title"
      class="elevation-1"
      loading-text="Loading... Please wait"
      :search="search"
      :custom-filter="filterText"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-row>
            <v-col cols="4">
              <span class="text-h6 primary--text">CONTRACT TEMPLATES</span>
            </v-col>
            <v-col cols="8">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="createModal"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-file-plus-outline</v-icon>
                  </v-btn>
                </template>
                <span>Create new </span>
              </v-tooltip>

              <v-text-field
                label="search..."
                v-model="search"
                append-icon="mdi-magnify"
                dense
                width="54"
                color="primary"
                class="float-right mx-2"
              ></v-text-field>

              <v-select
                v-model="editedItem.type"
                :items="[
                  { id: 'approved', title: 'Approved' },
                  { id: 'pending', title: 'Pending' },
                  { id: 'rejected', title: 'Rejected' },
                ]"
                label="Filter by status"
                item-text="title"
                item-value="id"
                color="primary"
                dense
                class="mx-2 float-right"
                :rules="selectRules"
              ></v-select>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark fab x-small v-bind="attrs" v-on="on" color="primary">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-btn
                color="info"
                class="mx-1"
                small
                outlined
                dark
                @click="viewItemModal(item)"
              >
                <v-icon small>mdi-view-carousel-outline</v-icon> Preview
              </v-btn>
            </v-list-item>

            <!-- Must be sent to eboard  -->
            <v-list-item v-if="item.amount > 5000 && item.type == 1">
              <v-btn
                color="error"
                class="mx-1 d-block"
                small
                outlined
                dark
                @click="deleteItem(item)"
              >
                <v-icon small>mdi-delete-outline</v-icon> Execute
              </v-btn>
              <v-btn
                v-if="item.sent_to_eboard_approval == 1"
                color="secondary"
                class="mx-1"
                small
                outlined
                dark
              >
                <v-icon>mdi-alpha-e-circle-outline</v-icon> Sent...
              </v-btn>
              <v-btn
                v-else
                color="primary"
                class="mx-1"
                small
                outlined
                dark
                @click="sendToEboard(item)"
              >
                <v-icon>mdi-alpha-e-circle-outline</v-icon> Send to eBoard
              </v-btn>
            </v-list-item>
            <!-- Not sent to eboard  -->

            <v-list-item>
              <v-btn
                color="alt"
                class="mx-1"
                small
                outlined
                dark
                @click="editItem(item)"
              >
                <v-icon small>mdi-pencil-outline</v-icon> Edit
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                color="error"
                class="mx-1 d-block"
                small
                outlined
                dark
                @click="deleteItem(item)"
              >
                <v-icon small>mdi-delete-outline</v-icon> Delete
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="90%">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="error--text text--darken-1">{{ formTitle }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.title"
                  label="Template Name"
                  outlined
                  dense
                  color="grey"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-n5">
                <v-card>
                  <editor
                    v-model="editedItem.content"
                    api-key="8g0uc9aqmyh9suvkt1n7m29cb3qc0686c3he2wnwmpp1smre"
                    :init="{
                      height: 900,
                      menubar: true,
                      autosave_ask_before_unload: false,
                      powerpaste_allow_local_images: true,
                      tinydrive_token_provider:
                        'http://localhost/apps/tenders/api/public/api/tinymce-jwt',
                      plugins: [
                        'tinydrive advlist anchor autolink codesample fullscreen help image imagetools tinydrive',
                        ' lists link media noneditable preview',
                        ' searchreplace table template visualblocks wordcount',
                      ],
                      toolbar:
                        'insertfile undo redo | bold italic | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link image tinydrive',
                      tinydrive_demo_files_url:
                        '/docs/demo/tiny-drive-demo/demo_files.json',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error"
              class="text-capitalize mx-1"
              dark
              @click="close"
            >
              Cancel <v-icon small>mdi-cancel</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="text-capitalize mx-1"
              :loading="loading"
              @click="save"
            >
              Submit <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_view" width="90%">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="primary">Preview Template</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.title"
                  label="Template Name"
                  outlined
                  readonly
                  dense
                  color="grey"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-n5">
                <v-card>
                  <div v-html="editedItem.content">

                  </div>

                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog_view = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" id="printarea">
        <!-- PDF Content Here -->
      </section>
    </vue-html2pdf>
  </div>
</template>

<style>
.ck-editor__editable {
  min-height: 250px;
}
.in-editor .ck-toolbar {
  display: inline !important;
}
</style>
<script>
import QuotationRequestService from "../../services/quotation_request.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ContractTemplateService from "../../services/contract_template.service";
import VueHtml2pdf from "vue-html2pdf";
import MainStepper from "../../components/MainStepper.vue";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: { VueHtml2pdf, MainStepper, Editor },
  data: () => ({
    e1: 2,
    name: "SupplierCategory",
    workflows: [],
    supplier_groups: [],
    selectRules: [(v) => !!v || "Select is required"],
    dialog_view: false,
    dialogSendToSuppliers: false,
    editor: ClassicEditor,
    editorConfig: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },

    editorConfig2: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      readOnly: true,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },

    loading: true,
    items: [],
    requisition_items: [],
    search: "",
    editedIndex: -1,
    editedItem: {
      title: "",
      status: "",
      content: "",
    },
    defaultItem: {
      title: "",
      status: "",
      content: "",
    },
    remember: 0,
    href: "workflows",
    timeout: 2000,
    dialog: false,
    valid: true,
    valid1: true,
    // declare extensions you want to use
    // extensions: [
    //   History,
    //   Blockquote,
    //   Link,
    //   Underline,
    //   Strike,
    //   Italic,
    //   ListItem,
    //   BulletList,
    //   OrderedList,
    //   [
    //     Heading,
    //     {
    //       options: {
    //         levels: [1, 2, 3],
    //       },
    //     },
    //   ],
    //   Bold,
    //   Code,
    //   HorizontalRule,
    //   Paragraph,
    //   HardBreak,
    // ],
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
      items: [(v) => !!v || "Field is required"],
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
        width: "5%",
      },
      {
        text: "Name/Subject",
        value: "title",
        width: "40%",
      },
      //   {
      //     text: "Status",
      //     value: "status",
      //     width: "10%",
      //   },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        sortable: false,
        width: "10%",
      },
    ],
  }),
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
    // required docs
    likesAllRequiredDocument() {
      return (
        this.editedItem.required_documents.length ===
        this.required_documents.length
      );
    },
    likesSomerequired_documents() {
      return (
        this.editedItem.required_documents.length > 0 &&
        !this.likesAllRequiredDocument
      );
    },
    iconRequired() {
      if (this.likesAllRequiredDocument) return "mdi-close-box";
      if (this.likesSomerequired_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.getAllData();
  },
  methods: {
    createModal() {
      this.editedIndex = -1;
      this.dialog = true;
      this.editedItem = {};
    },
    gotToMyApprovals() {
      this.$router.push("/requisition-approval");
    },
    goToNeedIdentification() {
      this.$router.push("/need-identification");
    },
    goToSupplierSelection() {
      this.$router.push("/supplier-selection");
    },
    selectNeed(item) {
      localStorage.setItem("selectedRequisition", JSON.stringify(item));
      this.$router.push("/supplier-selection/" + item.id);
    },
    displayType(type) {
      if (type == 1) {
        return "<v-chip color='orange'>Capex</v-chip>";
      } else {
        return "<v-chip color='primary'>Opex</v-chip>";
      }
    },
    toMoneyFormat(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    addNewItem() {
      this.requisition_items.push({ name: "", quantity: "" });
    },
    addNewEditedItem() {
      this.requisition_items.push({ name: "", quantity: "", edited: 1 });
    },
    generateReport() {
      var printable_content = document.getElementById("printable_content");
      var pdf = document.getElementById("printarea");
      pdf.innerHTML = printable_content.innerHTML;
      this.$refs.html2Pdf.generatePdf();
    },
    trimText(text, length) {
      text.substring(0, length);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem);
      this.dialog = true;
    },
    viewItemModal(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_view = true;
    },
    sendItemToSupplier(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialogSendToSuppliers = true;
    },

    // search
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getAllData() {
      return ContractTemplateService.index().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    viewItem(item) {
      this.$router.push("/suppliers-view/" + item.id);
    },
    sendToEboard(item) {
      item.approval_name = item.title;
      item.applicant_name = this.user.name;
      const index = this.items.indexOf(item);
      var sendConfirm = confirm("Confirm sending item for eBoard approval?");
      if (sendConfirm) {
        ContractTemplateService.sendToEboard(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.items.splice(index, 1);
              this.close();
              this.index();
            } else {
              this.$store.dispatch("alert/error", "Error sending approval");
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 500) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 502) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 401) {
              this.handleLogout();
            } else {
              this.$store.dispatch("alert/error", error.response.statusText);
            }
          }
        );
      }
    },

    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this template?");
      if (deleteUser) {
        ContractTemplateService.delete(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.items.splice(index, 1);
              this.close();
            } else {
              this.$store.dispatch("alert/error", "Error Deleting Template");
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 500) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 502) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 401) {
              this.handleLogout();
            } else {
              this.$store.dispatch("alert/error", error.response.statusText);
            }
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async handleFileUpload(file) {
      console.log("uploading...");
      await this.getBase64(file).then(
        (data) => (this.editedItem.attachment = data)
      );
    },

    save() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          console.log(this.editedItem);
          ContractTemplateService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.getAllData();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.getAllData();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.getAllData();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          // create
          ContractTemplateService.store(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Item created successfully"
                );
                this.loading = false;
                this.getAllData();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.getAllData();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      } else {
        this.loading = false;
        this.index();
      }
    },
    requestQuotation() {
      this.loading = true;
      this.$refs.form1.validate();
      if (this.valid) {
        this.editedItem.requisition_id = this.editedItem.id;
        this.editedItem.user_id = this.user.id;
        QuotationRequestService.store(this.editedItem).then(
          (response) => {
            console.log(response);
            if (response.status == 200) {
              this.$store.dispatch(
                "alert/success",
                "Request sent successfully"
              );
              this.loading = false;
              this.dialogSendToSuppliers = false;
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.dialogSendToSuppliers = false;
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
              this.dialogSendToSuppliers = false;
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.dialogSendToSuppliers = false;
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      } else {
        this.loading = false;
        this.index();
      }
    },
  },
};
</script>
